import { useSelector } from 'react-redux';

import Dashboard from './Contracts/Dashboard';
import SelectAgent from './Contracts/SelectAgent';


const Home = () => {
    const { role, agentEmail } = useSelector((state) => state.auth);

    if (role === "Transaction Coordinator" && !agentEmail) {
        return <SelectAgent />
    } else {
        return <Dashboard status="Pending" />
    }

}

export default Home