import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import validator from 'validator'

import Spinner from '../Layout/Spinner'
import { getAgentInfo, manageUsers } from "../../features/contractSlice"


const ManageUsers = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { role, email, idToken, agentEmail } = useSelector((state) => state.auth);
    const { agentInfo, loading, apiError, apiErrorMessage } = useSelector((state) => state.contracts)

    const [users, setUsers] = useState([])
    const [newUser, setNewUser] = useState("")
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [submitButtonVisible, setSubmitButtonVisible] = useState(false)

    // useEffect(() => {
    //     dispatch(getAgentInfo({ role, email, agentEmail, idToken }))
    //     console.log("agentInfo", agentInfo)
    //     if (agentInfo.subscription_users) {
    //         setUsers(agentInfo.subscription_users)
    //     }
    // }, [])

    useEffect(() => {
        dispatch(getAgentInfo({ role, email, agentEmail, idToken }))
    }, [dispatch, role, email, agentEmail, idToken])

    // Add a separate effect to handle agentInfo updates
    useEffect(() => {
        if (agentInfo?.subscription_users) {
            setUsers(agentInfo.subscription_users)
        }
    }, [agentInfo])


    const handleAddUser = () => {
        setError(false)
        setErrorMessage("")

        if (validator.isEmpty(newUser)) {
            setError(true)
            setErrorMessage("Cannot be empty")
        } else if (!validator.isEmail(newUser)) {
            setError(true)
            setErrorMessage("Please enter valid email")
        } else if (users.length > 3) {
            setError(true)
            setErrorMessage("You can have upto 4 users")
        } else {
            let lowercaseEmail = newUser.toLowerCase()
            setUsers([...users, newUser])
            setNewUser("")
            setSubmitButtonVisible(true)
        }

    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (users.length > 4) {
            setError(true)
            setErrorMessage("You can have upto 4 users")
        } else {
            const data = {
                email: email,
                subscription_users: users
            }

            dispatch(manageUsers({ data, idToken }))
                .unwrap()
                .then(() => {
                    setSuccessMessage('Updated Users successfully')
                    setSubmitButtonVisible(false)
                    // navigate("/sub/add-users")
                })
                .catch((error) => {
                    console.error('Error updating users:', error);
                });
        }
    }

    if (loading) return <Spinner />

    return (
        <div className='min-h-screen'>
            <div className='container-xl lg:container m-auto px-4 py-6'>
                {agentInfo && (
                    <div>
                        <div className='font-bold text-xl m-5'>Manage users for {email}</div>
                        {setSuccessMessage && (<div className='text-sm italic text-green-500'>{successMessage}</div>)}
                        {users.length < 4 && (
                            <div>
                                <div className='font-bold mt-5'>Add a new user</div>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-2'>
                                    <input
                                        className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40l md:col-span-2'
                                        type="email"
                                        placeholder='User email'
                                        value={newUser}
                                        onChange={(e) => {
                                            setNewUser(e.target.value)
                                            setSuccessMessage("")
                                        }}
                                    />
                                    <button
                                        className='uppercase font-bold text-blue-600 text-left col-span-1'
                                        onClick={handleAddUser}
                                    >
                                        Add
                                    </button>
                                </div>
                                {error && (
                                    <div className='italic text-sm text-red-600'>{errorMessage}</div>
                                )}
                            </div>
                        )}

                        <div className='mt-4'>
                            <div className='font-bold mt-5'>Users</div>
                            {users && users.map((user, index) => {
                                return (
                                    <div key={index} className='grid grid-cols-1 md:grid-cols-3 gap-2'>
                                        <div className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40l col-span-2'>{user}</div>
                                        <button
                                            className='uppercase font-bold text-red-600 text-left md:col-span-1 pt-1'
                                            onClick={() => {
                                                setUsers(users.filter((u, i) => i !== index))
                                                setSubmitButtonVisible(true)
                                            }}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                )
                            })}
                        </div>

                        {submitButtonVisible && (<div className='mt-5'>
                            <form onSubmit={handleSubmit}>
                                <button type="submit" className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-indigo-700 rounded-md hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600">
                                    Submit Changes
                                </button>
                            </form>
                        </div>)}

                    </div>
                )}

            </div>
        </div>
    )
}

export default ManageUsers