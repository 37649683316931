import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import validator from 'validator'
import { v4 as uuidv4 } from 'uuid';

import { sendInvitation } from "../../features/contractSlice"

const Invitation = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [friendEmail, setFriendEmail] = useState("")
    const [notes, setNotes] = useState("")
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const { email, idToken } = useSelector((state) => state.auth);
    const { apiError, apiErrorMessage } = useSelector((state) => state.contracts)

    const submitFormData = (e) => {
        e.preventDefault()

        const data = {
            invitation_id: uuidv4(),
            realtor_email: email,
            friend_email: friendEmail,
            message: notes
        }

        if (validator.isEmpty(friendEmail)) {
            setError(true)
            setErrorMessage("Friend Email is a required field")
        } else if (!validator.isEmail(friendEmail)) {
            setError(true)
            setErrorMessage("Please enter valid email")
        } else {
            dispatch(sendInvitation({ data, idToken }))
                .unwrap()
                .then(() => {
                    navigate(`/`);
                })
                .catch((error) => {
                    console.error('Error sending invitation:', error);
                });
        }
    }

    return (
        <div className='min-h-screen'>
            <div className="container-xl lg:container m-auto px-4 py-6">
                <div className='text-2xl text-center font-semibold mb-6 uppercase'>
                    Share
                </div>

                <form className="mt-5" onSubmit={submitFormData}>
                    <div className='mb-2'>
                        <input
                            className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            name="friendEmail"
                            placeholder="Email"
                            type="email"
                            value={friendEmail}
                            onChange={(e) => setFriendEmail(e.target.value)}
                        />
                    </div>
                    <div className='mb-2'>
                        <textarea
                            className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            name="seller_agent_email"
                            value={notes}
                            placeholder="(Optional) Message to your friend..."
                            type="email"
                            onChange={(e) => setNotes(e.target.value)}
                        />
                    </div>

                    {error && (
                        <div className='italic text-sm text-red-600'>{errorMessage}</div>
                    )}

                    {apiError && <div className='mt-5 italic text-lg text-center'>{apiErrorMessage}</div>}

                    <button
                        className='uppercase h-[36px] bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg text-center text-sm'
                        type="submit">
                        Send Message
                    </button>
                </form>

            </div>

        </div>
    )
}

export default Invitation