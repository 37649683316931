import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import RequireAuth from "./components/Auth/RequireAuth"
import RequireAgent from './components/Auth/RequireAgent'
import Home from "./components/Home"

import Login from "./components/Auth/Login"
import Signup from './components/Auth/SignUp'
import ConfirmSignup from './components/Auth/ConfirmSignup'
import ForgotPassword from './components/Auth/ForgotPassword'
import ResetPassword from './components/Auth/ResetPassword'

import Navbar from './components/Layout/Navbar'
import Footer from './components/Layout/Footer'
import About from './components/Others/About'

import AddContract from './components/Contracts/AddContract'
import ContractDetails from "./components/Contracts/ContractDetails"
import EditContractInfo from './components/Contracts/EditContractInfo'
import EditContractSteps from './components/Contracts/EditContractSteps'
import ArchiveContract from "./components/Contracts/ArchiveContract"
import ClosedContracts from "./components/Contracts/ClosedContracts"

import NotFound from './components/Others/NotFound'
import SelectAgent from "./components/Contracts/SelectAgent"
import ManageUsers from './components/Users/ManageUsers'

import PaymentCheckout from './components/Subscriptions/PaymentCheckout'
import CancelSubscription from './components/Subscriptions/CancelSubscription'
import ManageSubscriptions from './components/Subscriptions/ManageSubscriptions'


import Invitation from "./components/Others/Invitation"

const App = () => {
  return (
    <div className='font-display'>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={
            <RequireAuth>
              <Home />
            </RequireAuth>} />
          <Route path="/closed-contracts" element={
            <RequireAuth>
              <RequireAgent>
                <ClosedContracts />
              </RequireAgent>
            </RequireAuth>} />
          <Route path="/add-contract" element={
            <RequireAuth>
              <RequireAgent>
                <AddContract />
              </RequireAgent>
            </RequireAuth>} />
          <Route
            path="/contract-details/:id"
            element={
              <RequireAuth>
                <RequireAgent>
                  <ContractDetails />
                </RequireAgent>
              </RequireAuth>}
          />
          <Route
            path="/edit-contract-info/:id"
            element={
              <RequireAuth>
                <RequireAgent>
                  <EditContractInfo />
                </RequireAgent>
              </RequireAuth>}
          />
          <Route
            path="/edit-contract-steps/:id"
            element={
              <RequireAuth>
                <RequireAgent>
                  <EditContractSteps />
                </RequireAgent>
              </RequireAuth>}
          />
          <Route
            path="/archive-contract/:id"
            element={
              <RequireAuth>
                <RequireAgent>
                  <ArchiveContract />
                </RequireAgent>
              </RequireAuth>}
          />

          <Route
            path="/sub/add-users"
            element={<RequireAuth><ManageUsers /></RequireAuth>}
          />
          <Route path="/sub" element={<RequireAuth><ManageSubscriptions /></RequireAuth>} />
          <Route path="/sub/payment/:id" element={<RequireAuth><PaymentCheckout /></RequireAuth>} />
          <Route path="/sub/cancel/:id" element={<RequireAuth><CancelSubscription /></RequireAuth>} />
          <Route path="/select-agent" element={<RequireAuth><SelectAgent /></RequireAuth>} />
          {/* <Route path='/role' element={<Role />} /> */}
          <Route path="signup" element={<Signup />} />
          <Route path="confirm-signup/:id" element={<ConfirmSignup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/about" element={<About />} />
          <Route path="/invitation" element={<Invitation />} />
          <Route path="/*" element={<NotFound />}></Route>
        </Routes>
        <Footer />
      </Router>
    </div>
  )
}

export default App
