import { useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import validator from 'validator'

import { confirmSignUp } from "../../utils/CognitoAuth"

const ConfirmSignup = () => {
    const navigate = useNavigate()
    const { id } = useParams()

    const [confirmationCode, setConfirmationCode] = useState("")
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (validator.isEmpty(confirmationCode)) {
            setError(true)
            setErrorMessage("Confirmation Code cannot be empty")
        } else {
            setError(false)

            try {
                const result = await confirmSignUp(id, confirmationCode)
                navigate("/login")
            } catch (e) {
                setError(true)
                console.log("Error confirming user:", e)
                setErrorMessage(e.message)
            }
        }

    }
    return (
        <div className='relative flex flex-col min-h-screen overflow-hidden'>
            <div className="w-full p-6 mx-auto bg-white rounded-md shadow-md lg:max-w-xl">
                <div className='font-bold uppercase text-2xl'>Confirm User</div>
                <div

                    className="text-sm italic text-indigo-500"
                >
                    Check your email for confirmation code. Please check spam/junk folder as well.
                </div>
                <form className="mt-5" onSubmit={handleSubmit}>
                    <div className='mb-2'>
                        <div className="font-bold text-lg">
                            Email: {id}
                        </div>
                    </div>
                    <div className='mb-2'>
                        <input
                            className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            name="confirmationCode"
                            placeholder="Confirmation Code"
                            type="text"
                            value={confirmationCode}
                            onChange={(e) => setConfirmationCode(e.target.value)}
                        />
                    </div>

                    {error && (
                        <div className='italic text-sm text-red-600'>{errorMessage}</div>
                    )}
                    <div className="mt-6">
                        <button type="submit" className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-indigo-700 rounded-md hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600">
                            Register
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ConfirmSignup