import { useState } from 'react'
import { createContract } from "../../features/contractSlice"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import validator from "validator";
import { AddressAutofill } from '@mapbox/search-js-react';

import { v4 as uuidv4 } from 'uuid';

import { workflow_steps_v2, determineAuthorization, determineAuthorizationV2 } from "../../utils/Helper"
import { inValidDate, duration } from "../../utils/Validations"

const AddContract = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const mapbox_access_token = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

    const [addressError, setAddressError] = useState(false);
    const [datesError, setDatesError] = useState(false);

    const { role, email, idToken, agentEmail } = useSelector((state) => state.auth);
    const { newContractId, agentInfo, stripeSubs, apiError, apiErrorMessage } = useSelector((state) => state.contracts)

    const initData = {
        address: '',
        apartment: '',
        city: '',
        state: '',
        country: 'US',
        zipcode: '',
        agent_name: '',
        agent_email: '',
        agent_phone: '',
        buyer_name: '',
        buyer_email: '',
        buyer_phone: '',
        co_buyer_name: '',
        co_buyer_email: '',
        co_buyer_phone: '',
        txn_coordinator_name: '',
        txn_coordinator_email: '',
        txn_coordinator_phone: '',
        seller_name: '',
        seller_email: '',
        seller_phone: '',
        co_seller_name: '',
        co_seller_email: '',
        co_seller_phone: '',
        loan_officer: '',
        loan_officer_email: '',
        loan_officer_phone: '',
        title_company: '',
        title_company_email: '',
        title_company_phone: '',
        contract_acceptance_date: '',
        closing_date: '',
        steps: [],
        contract_status: '',
        createdBy: '',
        updatedBy: '',
    }

    const [data, setData] = useState(initData)

    const handleInputData = input => e => {
        const { value } = e.target

        setData(prevState => ({
            ...prevState,
            [input]: value
        }))
    }

    let { freeTrial, active, expDate } = determineAuthorizationV2(role, stripeSubs, agentInfo)

    const submitFormData = (e) => {
        e.preventDefault()

        if (
            validator.isEmpty(data.address) ||
            validator.isEmpty(data.city) ||
            validator.isEmpty(data.state) ||
            validator.isEmpty(data.zipcode)
        ) {
            setAddressError(true)
        } else if (inValidDate(data.contract_acceptance_date) || inValidDate(data.closing_date) || duration(data.contract_acceptance_date, data.closing_date) < 1) {
            setAddressError(false)
            setDatesError(true)
        } else {
            if (role === "Agent") {
                data.agent_email = email
            } else if (role === "Transaction Coordinator") {
                data.agent_email = agentEmail
            }
            data.contract_id = uuidv4()
            data.agent_email = data.agent_email.toLowerCase()
            data.buyer_email = data.buyer_email.toLowerCase()
            data.co_buyer_email = data.co_buyer_email.toLowerCase()
            data.txn_coordinator_email = data.txn_coordinator_email.toLowerCase()
            data.seller_email = data.seller_email.toLowerCase()
            data.co_seller_email = data.co_seller_email.toLowerCase()

            data.loan_officer_email = data.loan_officer_email.toLowerCase()
            data.title_company_email = data.title_company_email.toLowerCase()

            let steps = workflow_steps_v2(data.contract_acceptance_date, data.closing_date)
            data.steps = steps
            data.contract_status = "Pending"
            data.createdBy = email
            data.updatedBy = email
            dispatch(createContract({ data, idToken }))
                .unwrap()
                .then(() => {
                    navigate(`/contract-details/${data.contract_id}`);
                })
                .catch((error) => {
                    console.error('Error creating contract:', error);
                });

        }
    }

    return (
        <div className='min-h-screen'>
            <div className="container-xl lg:container m-auto px-4 py-6">
                <div className='text-2xl text-center font-semibold mb-6 uppercase'>Create Contract</div>
                {(active || freeTrial) ? (
                    <div>
                        <form onSubmit={submitFormData}>
                            <div>
                                <div className='text-xl text-center font-semibold mb-6'>Address</div>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                    <div className='mb-2'>
                                        <AddressAutofill accessToken={mapbox_access_token}>
                                            <input
                                                className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                                name="address"
                                                defaultValue={data.address}
                                                placeholder="Address"
                                                type="text"
                                                autoComplete="address"
                                                onChange={handleInputData("address")}
                                            />
                                        </AddressAutofill>
                                        {addressError ? (
                                            <span className='italic text-sm text-red-600'>Address is a required field</span>
                                        ) : (
                                            ""
                                        )}

                                    </div>
                                    <div className='mb-2'>
                                        <input
                                            className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                            name="apartment"
                                            placeholder="Apartment number"
                                            type="text"
                                            autoComplete="address-line2"
                                            defaultValue={data.apartment}
                                            onChange={handleInputData("apartment")}
                                        />
                                    </div>

                                    <div className='mb-2'>
                                        <input
                                            className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                            name="city"
                                            placeholder="City"
                                            type="text"
                                            autoComplete="address-level2"
                                            defaultValue={data.city}
                                            onChange={handleInputData("city")}
                                        />
                                        {addressError ? (
                                            <span className='italic text-sm text-red-600'>City is a required field</span>
                                        ) : (
                                            ""
                                        )}
                                    </div>

                                    <div className='mb-2'>
                                        <input
                                            className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                            name="state"
                                            placeholder="State"
                                            type="text"
                                            autoComplete="address-level1"
                                            defaultValue={data.state}
                                            onChange={handleInputData("state")}
                                        />
                                        {addressError ? (
                                            <span className='italic text-sm text-red-600'>State is a required field</span>
                                        ) : (
                                            ""
                                        )}
                                    </div>

                                    <div className='mb-2'>
                                        <input
                                            className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                            name="zipcode"
                                            placeholder="Zipcode"
                                            type="text"
                                            autoComplete="postal-code"
                                            defaultValue={data.zipcode}
                                            onChange={handleInputData("zipcode")}
                                        />
                                        {addressError ? (
                                            <span className='italic text-sm text-red-600'>Zipcode is a required field</span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>

                                <div className='text-xl text-center font-semibold mt-6'>Dates</div>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                    <div className='mb-2'>
                                        <label className='font-semibold text-xl mt-5'>Acceptance Date</label>
                                        <input
                                            className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                            name="contract_acceptance_date"
                                            type="date"
                                            onChange={handleInputData("contract_acceptance_date")}
                                        />
                                    </div>

                                    <div className='mb-2'>
                                        <label className='font-semibold text-xl mt-5'>Closing Date</label>
                                        <input
                                            className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                            name="closing_date"
                                            type="date"
                                            onChange={handleInputData("closing_date")}
                                        />

                                    </div>
                                    {datesError ? (
                                        <span className='italic text-sm text-red-600 md:col-span-2'>Check the dates. Either dates are empty or Closing date is earlier or equal to Agreement date</span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>

                            <div>
                                <button className='bg-blue-500 hover:bg-blue-600 text-white font-bold my-4 py-2 px-4 rounded-full w-full focus:outline-none focus:shadow-outline'
                                    type='submit'>Create Contract</button>
                            </div>
                        </form>
                    </div>
                ) : (
                    <div>
                        You have not subscribed to Contract To Keys.
                        <button
                            className='w-[150px] h-[36px] bg-gray-500 hover:bg-gray-600 text-white rounded-lg text-center text-sm'>
                            <Link to={"/sub/"}>Subscribe</Link>
                        </button>
                    </div>
                )}
            </div>

            {apiError && <div className='mt-5 italic text-lg text-center'>{apiErrorMessage}</div>}
        </div>
    )
}

export default AddContract