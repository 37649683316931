import { subscriptionGracePeriodInDays } from "../utils/Constants"
import { format, parseISO } from 'date-fns';

export const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}

export const parseIdToken = (idToken) => {
    return parseJwt(idToken)
}

// export const addDays = (dateString, days) => {

//     const date = new Date(dateString);
//     const newDate = new Date(date.getTime() + (days * 24 * 60 * 60 * 1000));

//     const month = String(newDate.getMonth() + 1).padStart(2, '0');
//     const day = String(newDate.getDate()).padStart(2, '0');
//     const year = newDate.getFullYear();
//     const newDateStringFormatted = `${year}-${month}-${day}`;

//     return newDateStringFormatted
// }

export const addDays = (dateString, days) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + days); // This handles month/year rollovers automatically

    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    const newDateStringFormatted = `${year}-${month}-${day}`;

    return newDateStringFormatted;
}


export const formatAddress = (address, city, state, postcode) => {
    return address + " " + city + ", " + state + " " + postcode
}

export const determineAuthorization = (role, subscriptions, agentInfo) => {

    let active = false
    let freeTrial = false
    let expDate = ''


    if (role === "Agent" || role === "Transaction Coordinator") {
        if (subscriptions.length === 0) {
            if (agentInfo && agentInfo.created_on) {
                const expiryDate = addDays(agentInfo.created_on.split("T")[0], subscriptionGracePeriodInDays)
                if (new Date(expiryDate) > new Date()) {
                    expDate = expiryDate
                    freeTrial = true
                } else {
                    freeTrial = false
                }
            }
        } else {
            const subs = subscriptions.filter(sub => new Date(sub.sub_end_date) > new Date())

            if (subs.length > 0) {
                active = true
                expDate = subs[0].end_date
            } else {
                active = false
            }
        }
    }

    return { freeTrial, active, expDate }
}
export const determineAuthorizationV2 = (role, subscriptions, agentInfo) => {

    let active = false
    let freeTrial = false
    let expDate = ''
    let subCancelledButActive = false

    if (role === "Agent" || role === "Transaction Coordinator") {
        if (subscriptions.length === 0) {
            if (agentInfo && agentInfo.created_on) {
                const expiryDate = addDays(agentInfo.created_on.split("T")[0], subscriptionGracePeriodInDays)
                if (new Date(expiryDate) > new Date()) {
                    expDate = expiryDate
                    freeTrial = true
                } else {
                    freeTrial = false
                }
            }
        } else {
            const activeStatusSubs = subscriptions.filter(sub => sub.status === "active")
            const canceledStatusSubs = subscriptions.filter(sub => sub.status === "canceled")
            subCancelledButActive = activeStatusSubs.length === 0 ? true : false

            const getMaxCurrentPeriodEnd = (subscriptions) => {
                return subscriptions.reduce((maxDate, subscription) => {
                    return Math.max(maxDate, subscription.current_period_end);
                }, 0);
            };

            if (subCancelledButActive) {
                expDate = new Date(getMaxCurrentPeriodEnd(canceledStatusSubs) * 1000).toISOString().split('T')[0]
            }

            active = true
        }
    }

    return { freeTrial, active, subCancelledButActive, expDate }
}

export const workflow_steps_v2 = (contract_date, closing_date) => {

    // T00:00:00 is added to accomodate local timezone
    const contract_dt = new Date(`${contract_date}T00:00:00`);
    const closing_dt = new Date(`${closing_date}T00:00:00`);

    const escrow_dt = addDays(contract_dt, 1)
    const inspection_scheduled_dt = addDays(contract_dt, 2)
    const inspection_completed_dt = addDays(contract_dt, 10)
    const binsr_request_dt = addDays(contract_dt, 10)
    const binsr_response_dt = addDays(new Date(`${binsr_request_dt}T00:00:00`), 5)
    const buyer_response_to_binsr = addDays(new Date(`${binsr_response_dt}T00:00:00`), 5)
    const appraisal_request_dt = addDays(new Date(`${buyer_response_to_binsr}T00:00:00`), 1)
    const appraisal_response_dt = addDays(new Date(`${appraisal_request_dt}T00:00:00`), 5)
    const approvals_loan_response_dt = addDays(closing_dt, -3)
    const final_walkthru_dt = addDays(closing_dt, -3)

    const steps = [
        {
            "step": 1,
            "title": "Contract Accepted",
            "due_date": contract_date,
            "status": "Completed",
            "comments": ""
        },
        {
            "step": 2,
            "title": "Open Escrow File - Earnest money deposited",
            "due_date": escrow_dt,
            "status": "Not Started",
            "comments": ""
        },
        {
            "step": 3,
            "title": "Inspection Scheduled",
            "due_date": inspection_scheduled_dt,
            "status": "Not Started",
            "comments": ""
        },
        {
            "step": 4,
            "title": "Inspection Completed",
            "due_date": inspection_completed_dt,
            "status": "Not Started",
            "comments": ""
        },
        {
            "step": 5,
            "title": "Repairs request (BINSR) sent to Listing agent",
            "due_date": binsr_request_dt,
            "status": "Not Started",
            "comments": ""
        },
        {
            "step": 6,
            "title": "Repairs request (BINSR) seller response received",
            "due_date": binsr_response_dt,
            "status": "Not Started",
            "comments": ""
        },
        {
            "step": 7,
            "title": "Buyer Response to Seller BINSR Response",
            "due_date": buyer_response_to_binsr,
            "status": "Not Started",
            "comments": ""
        },
        {
            "step": 8,
            "title": "Apprisal Ordered",
            "due_date": appraisal_request_dt,
            "status": "Not Started",
            "comments": ""
        },
        {
            "step": 9,
            "title": "Apprisal Completed",
            "due_date": appraisal_response_dt,
            "status": "Not Started",
            "comments": ""
        },
        {
            "step": 10,
            "title": "Final Loan Approval & Loan Docs to title",
            "due_date": approvals_loan_response_dt,
            "status": "Not Started",
            "comments": ""
        },
        {
            "step": 11,
            "title": "Final Walkthrough",
            "due_date": final_walkthru_dt,
            "status": "Not Started",
            "comments": ""
        },
        {
            "step": 12,
            "title": "Recording Confirmation - release keys to buyer",
            "due_date": closing_date,
            "status": "Not Started",
            "comments": ""
        },
        {
            "step": 13,
            "title": "Request a review",
            "due_date": closing_date,
            "status": "Not Started",
            "comments": ""
        },
    ]

    return steps

}

export const determineContract_Status = (list) => {
    const countBy = (arr, prop) => arr.reduce((prev, curr) => (prev[curr[prop]] = ++prev[curr[prop]] || 1, prev), {})

    const workflow_status = countBy(list, "status")

    // ["Not Started", "In-progress", "Completed", "Not required"]

    let completed = workflow_status["Completed"]
    let not_required = workflow_status["Not required"]

    if ((completed === list.length) || (completed + not_required === list.length)) {
        return "Completed"
    }

    return "Pending"
}

export const toProperCase = (word) => {
    if (typeof word !== 'string' || word.length === 0) {
        return word;
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export const determineAccess = (role, email, agentEmail, contract) => {

    let access = false;

    switch (role) {
        case "Agent":
            if (email === contract.agent_email) {
                access = true
            }
            break;
        case "Transaction Coordinator":
            if (agentEmail === contract.agent_email) {
                access = true
            }
            break;
        case "Buyer":
            if (email === contract.buyer_email) {
                access = true
            }
            break;
        case "Seller":
            if (email === contract.seller_email) {
                access = true
            }
            break;
        default:
            access = false
    }

    return access
}