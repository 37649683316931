import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'


import Spinner from "../Layout/Spinner"
import DisplayContarctsTable from './DisplayContarctsTable';

import { getAgentInfo, getSubscriptions, getAllSubs, getContracts } from "../../features/contractSlice"
import { determineAuthorizationV2 } from '../../utils/Helper';

const Dashboard = ({ status }) => {
    const { role, email, idToken, agentEmail } = useSelector((state) => state.auth);
    const { contracts, agentInfo, stripeSubs, loading, apiError, apiErrorMessage } = useSelector((state) => state.contracts)

    const dispatch = useDispatch()

    useEffect(() => {

        switch (role) {
            case "Agent":
                dispatch(getAllSubs({ email, idToken }));
                dispatch(getAgentInfo({ role, email, agentEmail, idToken }));
                dispatch(getContracts({ role, email, idToken, agentEmail, status }))
                break;
            case "Transaction Coordinator":
                dispatch(getAllSubs({ email: agentEmail, idToken }));
                dispatch(getAgentInfo({ role, email, agentEmail, idToken }));
                dispatch(getContracts({ role, email, idToken, agentEmail, status }))
                break;
            case "Buyer":
                dispatch(getContracts({ role, email, idToken, agentEmail, status }))
                break;
            case "Seller":
                dispatch(getContracts({ role, email, idToken, agentEmail, status }))
                break;
            default:
                break;
        }
    }, [])

    let { freeTrial, active, expDate } = determineAuthorizationV2(role, stripeSubs, agentInfo)

    const sortedContracts = [...contracts].sort((a, b) => {
        return new Date(a.closing_date) > new Date(b.closing_date) ? 1 : -1
    })


    if (loading) return <Spinner />

    if (sortedContracts.length === 0) {
        return <div>
            No <span className='uppercase font-bold'>{status}</span> contracts
        </div>
    }

    if (role === "Buyer" || role === "Seller") {
        return <DisplayContarctsTable contracts={sortedContracts} />
    }

    if (role === "Agent" || role === "Transaction Coordinator") {
        if (!active && !freeTrial) {
            return <div>
                You have not subscribed to Contract To Keys.
                <button
                    className='w-[150px] h-[36px] bg-gray-500 hover:bg-gray-600 text-white rounded-lg text-center text-sm'>
                    <Link to={"/sub/"}>Subscribe</Link>
                </button>
            </div>
        }

        return <DisplayContarctsTable contracts={sortedContracts} />
    }


    if (apiError) return <div className='mt-5 italic text-lg text-center'>{apiErrorMessage}</div>

    return <div>Invalid role</div>;

}

export default Dashboard