import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom'

import { setAgentForTC } from "../../features/authSlice"
import { getRealtorsForTC } from "../../features/contractSlice"

const SelectAgent = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [realtor, setRealtor] = useState("")

    const { email, idToken, agentEmail } = useSelector((state) => state.auth);
    const { realtorsForTC, error, message } = useSelector((state) => state.contracts);

    const [validationError, setValidationError] = useState(false)
    const [errorMessage, setErrorMessage] = useState()

    useEffect(() => {
        dispatch(getRealtorsForTC({ email, idToken }))
    }, [])

    const submitFormData = (e) => {
        e.preventDefault()

        if (realtorsForTC.indexOf(realtor) === -1) {
            setValidationError(true)
            setErrorMessage("Please enter a valid email")
        } else {
            dispatch(setAgentForTC(realtor))
            navigate("/")
        }
    }

    return (
        <div className='min-h-screen'>
            <div className="container-xl lg:container m-auto px-4 py-6">
                <div className='text-2xl text-center font-semibold mb-6 uppercase'>Choose Agent</div>
                <form onSubmit={submitFormData}>
                    <div className='mb-2'>
                        <select
                            className="w-full border rounded h-10"
                            name="realtor"
                            value={realtor}
                            onChange={(e) => setRealtor(e.target.value)}>
                            <option>Select Agent Email</option>
                            {realtorsForTC.map((realtor, index) => (
                                <option key={index}>{realtor}</option>
                            ))}
                        </select>
                    </div>
                    {validationError && (
                        <div className='italic text-sm text-red-600'>{errorMessage}</div>
                    )}
                    <div>
                        <button className='bg-blue-500 hover:bg-blue-600 text-white font-bold my-4 py-2 px-4 rounded-full w-full focus:outline-none focus:shadow-outline'
                            type='submit'>Submit</button>
                    </div>
                </form>
            </div>

        </div>
    )
}

export default SelectAgent