import React from 'react'
import { Link } from 'react-router-dom'
import { format, parseISO } from 'date-fns';

import { formatAddress } from '../../utils/Helper';

const DisplayContarctsTable = ({ contracts }) => {
    return (
        <div className="overflow-x-auto">
            <table className="w-full table-auto">
                <thead>
                    <tr className="bg-gray-200">
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Address
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Closing date
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Days to closing
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {contracts.map((contract, index) => {
                        const days_to_closure = Math.round((new Date(contract.closing_date) - new Date()) / 86400000, 0)
                        return <tr key={index} className="hover:bg-gray-100">
                            <td className="px-6 py-4 text-sm font-medium text-gray-900 text-blue-500 underline font-bold">
                                <Link to={`/contract-details/${contract.contract_id}`}>
                                    {formatAddress(contract.address, contract.city, contract.state, contract.zipcode)}
                                </Link>

                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {format(parseISO(contract.closing_date), 'MM/dd/yyyy')}
                            </td>
                            <td className={`px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center ${days_to_closure > 20 ? " bg-green-200" : days_to_closure > 10 ? "bg-orange-200" : "bg-red-200"}`}>
                                {days_to_closure}
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default DisplayContarctsTable