import React, { useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios';

const CancelSubscription = () => {
    const { id } = useParams()
    const { email, idToken } = useSelector((state) => state.auth);

    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [succeeded, setSucceeded] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setProcessing(true);

        try {
            // Call your server to cancel the subscription
            const url = `https://szzfbdwon6.execute-api.us-east-2.amazonaws.com/DEV/stripesubscriptioncancel`
            const config = {
                "headers": {
                    "Authorization": idToken
                }
            }

            const data = {
                subscriptionId: id
            }

            const response = await axios.post(
                url, data, config
            );

            if (response.data.status === 'canceled') {
                setError(null);
                setSucceeded(true);
                setProcessing(false);
            } else {
                setError('Failed to cancel subscription: ' + response.data.message);
                setProcessing(false);
            }
        } catch (err) {
            setError('Failed to cancel subscription: ' + err.message);
            setProcessing(false);
        }
    };

    return (
        <div className='min-h-64 px-4 py-6'>
            <div className="container-xl lg:container m-auto px-4 py-6">
                <div className='text-2xl text-center font-semibold mb-6 uppercase'>Cancel Subscription</div>
                <form onSubmit={handleSubmit}>
                    <div className="font-bold text-xl">Do you want to cancel your subscription?</div>

                    {error && <div style={{ color: 'red' }}>{error}</div>}
                    {succeeded && <div style={{ color: 'green' }}>Subscription successfully canceled!</div>}
                    {/* <button type="submit" disabled={processing || !id}>
                        {processing ? 'Processing...' : 'Cancel Subscription'}
                    </button> */}
                    <button type="submit" disabled={processing || !id} className='w-[150px] h-[36px] bg-red-500 hover:bg-red-600 text-white rounded-lg text-center text-sm mb-2' >
                        {processing ? 'Processing...' : 'Cancel Subscription'}
                    </button>
                </form>
            </div>
        </div>
    );

}

export default CancelSubscription