import { createSlice } from "@reduxjs/toolkit";
import { saveToLocalStorage, getFromLocalStorage } from "../utils/localStorage";
import { parseIdToken } from "../utils/Helper"

const initialState = {
    email: getFromLocalStorage("email"),
    role: getFromLocalStorage("role"),
    agentEmail: getFromLocalStorage("agentEmail"),
    idToken: getFromLocalStorage("idToken"),
    accessToken: getFromLocalStorage("accessToken"),
    refreshToken: getFromLocalStorage("refreshToken"),
    refreshTokenExp: getFromLocalStorage("refreshTokenExp"),
    isAuthenticated: !!getFromLocalStorage("idToken")
}

export const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action) => {
            const { email, role, IdToken, AccessToken, RefreshToken } = action.payload;
            const idTokenParsed = parseIdToken(IdToken)
            const refreshTokenExp = (idTokenParsed.iat * 1000) + (29 * 24 * 60 * 60 * 1000)

            state.email = email;
            state.role = role;
            state.idToken = IdToken;
            state.accessToken = AccessToken;
            state.refreshToken = RefreshToken;
            state.refreshTokenExp = refreshTokenExp;
            saveToLocalStorage("email", email);
            saveToLocalStorage("role", role);
            saveToLocalStorage("idToken", IdToken);
            saveToLocalStorage("accessToken", AccessToken);
            saveToLocalStorage("refreshToken", RefreshToken);
            saveToLocalStorage("refreshTokenExp", refreshTokenExp);
        },
        setNewTokens: (state, action) => {
            const { IdToken, AccessToken } = action.payload;
            state.accessToken = AccessToken;
            state.idToken = IdToken;
            saveToLocalStorage("accessToken", AccessToken);
            saveToLocalStorage("idToken", IdToken);
        },
        setAgentForTC: (state, action) => {
            const agent = action.payload
            state.agentEmail = agent
            saveToLocalStorage("agentEmail", agent);
        },
        logout: (state) => {
            state.email = null;
            state.role = null;
            state.agentEmail = null;
            state.idToken = null;
            state.accessToken = null;
            state.refreshToken = null;
            state.refreshTokenExp = null;
            localStorage.clear()
        }
    }
})

export const { login, setNewTokens, setAgentForTC, logout } = auth.actions;
export default auth.reducer;