import React, { useEffect } from 'react'
import logo from "../../assets/contracttokeys.png"
import { format, parseISO } from 'date-fns';

import { useSelector, useDispatch } from 'react-redux'
import { determineAuthorizationV2 } from '../../utils/Helper';
import { getAgentInfo, getAllSubs } from "../../features/contractSlice"

const Footer = () => {
    const dispatch = useDispatch()

    const currentYear = new Date().getFullYear();

    const { role, email, idToken, agentEmail } = useSelector((state) => state.auth);
    const { stripeSubs, agentInfo } = useSelector((state) => state.contracts)

    useEffect(() => {
        dispatch(getAllSubs({ email, idToken }))
        dispatch(getAgentInfo({ role, email, agentEmail, idToken }))
    }, [])

    let { freeTrial, active, subCancelledButActive, expDate } = determineAuthorizationV2(role, stripeSubs, agentInfo)

    return (
        <footer>
            {(freeTrial) && <div className='mt-5 italic text-lg text-center'>{`Your free trial ends on ${format(parseISO(expDate), "MM/dd/yyyy")}`}</div>}

            {(subCancelledButActive) && <div className='mt-5 italic text-lg text-center'>{`Your current subscription ends on ${format(parseISO(expDate), "MM/dd/yyyy")}. Please re-subscribe`}</div>}
            <div className='bg-gray-200 py-4'>
                <div className='container mx-auto flex flex-col md:flex-row items-center justify-around px-4 text-gray-500'>
                    {/* <FaHouseUser size={40} /> */}
                    <img className="object-fit h-14 w-14" src={logo} alt="logo" />
                    <div>
                        <p className='italic mt-2 md:mt-0'>
                            &copy; {currentYear} <span className='font-bold uppercase'>Contract To Keys</span>
                        </p>
                        <p className='font-semibold text-sm italic text-right'>Your Closing Countdown</p>
                    </div>

                    <div className='italic text-center text-sm'>All rights reserved. <span className='font-bold'>Logan Hall, Vivek Amble and Partha Sastry</span></div>
                </div>
            </div>
        </footer>
    )
}

export default Footer