import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

const initialState = {
    contracts: [],
    agentInfo: {},
    subscriptions: [],
    stripeSubs: [],
    realtorsForTC: [],
    newContractId: '',
    updateContractId: '',
    contract: {},
    newSubscriptionId: {},
    loading: false,
    apiError: false,
    apiErrorMessage: ""
}

export const getContracts = createAsyncThunk(
    "contracts/get",
    async ({ role, email, idToken, agentEmail, status }) => {

        let query_role

        switch (role) {
            case "Agent":
                query_role = "agent_email"
                break
            case "Transaction Coordinator":
                query_role = "agent_email"
                break
            case "Buyer":
                query_role = "buyer_email"
                break
            case "Seller":
                query_role = "seller_email"
                break
            default:
                query_role = ""
        }

        let url
        if (role === "Agent" || role === "Seller" || role === "Buyer") {
            url = `${process.env.REACT_APP_API_BASE_URL}/contracts?role=${query_role}&email=${email}&status=${status}`
        } else if (role === "Transaction Coordinator") {
            url = `${process.env.REACT_APP_API_BASE_URL}/contracts?role=${query_role}&email=${agentEmail}&status=${status}`
        }

        const config = {
            "headers": {
                "Authorization": idToken
            }
        }

        const res = await axios.get(url, config)
        return res.data.Items;
    }
);

export const getAgentInfo = createAsyncThunk(
    "agentinfo/get",
    async ({ role, email, agentEmail, idToken }) => {

        let url;
        if (role === "Agent") {
            url = `${process.env.REACT_APP_API_BASE_URL}/sub-users?email=${email}`
        } else if (role === "Transaction Coordinator") {
            url = `${process.env.REACT_APP_API_BASE_URL}/sub-users?email=${agentEmail}`
        }

        const config = {
            "headers": {
                "Authorization": idToken
            }
        }

        const res = await axios.get(url, config)

        return res.data.Items[0];
    }
);

export const getSubscriptions = createAsyncThunk(
    "subscription/get",
    async ({ role, email, agentEmail, idToken }) => {

        let url;
        if (role === "Agent") {
            url = `${process.env.REACT_APP_API_BASE_URL}/subscriptions?email=${email}`
        } else if (role === "Transaction Coordinator") {
            url = `${process.env.REACT_APP_API_BASE_URL}/subscriptions?email=${agentEmail}`
        }

        const config = {
            "headers": {
                "Authorization": idToken
            }
        }

        const res = await axios.get(url, config)
        return res.data.Items;
    }
);

export const getRealtorsForTC = createAsyncThunk(
    "realtorsfortc/get",
    async ({ email, idToken }) => {

        const url = `${process.env.REACT_APP_API_BASE_URL}/sub-users/realtors?email=${email}`
        const config = {
            "headers": {
                "Authorization": idToken
            }
        }

        const res = await axios.get(url, config)
        return res.data;
    }
);

export const createContract = createAsyncThunk(
    "contract/post",
    async ({ data, idToken }) => {

        const url = `${process.env.REACT_APP_API_BASE_URL}/contract`
        const config = {
            "headers": {
                "Authorization": idToken
            }
        }

        const res = await axios.post(url, data, config)
        return res.data;
    }
);

export const getContract = createAsyncThunk(
    "contract/get",
    async ({ contract_id, idToken }) => {

        const url = `${process.env.REACT_APP_API_BASE_URL}/contract?contract_id=${contract_id}`;
        const config = {
            "headers": {
                "Authorization": idToken
            }
        }

        const res = await axios.get(url, config)
        return res.data;
    }
);

export const updateContract = createAsyncThunk(
    "contract/update",
    async ({ data, idToken }) => {

        const url = `${process.env.REACT_APP_API_BASE_URL}/contract`

        const config = {
            "headers": {
                "Authorization": idToken
            }
        }

        const res = await axios.patch(url, data, config)
        return res.data;
    }
);

export const archiveContract = createAsyncThunk(
    "contract/archive",
    async ({ archiveData, idToken }) => {

        const url = `${process.env.REACT_APP_API_BASE_URL}/contract/archive`


        const config = {
            "headers": {
                "Authorization": idToken
            }
        }

        const res = await axios.patch(url, archiveData, config)
        return res.data;
    }
);

export const manageUsers = createAsyncThunk(
    "users/update",
    async ({ data, idToken }) => {

        const url = `${process.env.REACT_APP_API_BASE_URL}/sub-users`

        const config = {
            "headers": {
                "Authorization": idToken
            }
        }

        const res = await axios.patch(url, data, config)
        return res.data;
    }
);

export const createSubscription = createAsyncThunk(
    "sub/post",
    async ({ data, idToken }) => {

        const url = `${process.env.REACT_APP_API_BASE_URL}/subscription`
        const config = {
            "headers": {
                "Authorization": idToken
            }
        }

        const res = await axios.post(url, data, config)
        return res.data;
    }
);

export const updateSubscription = createAsyncThunk(
    "sub/update",
    async ({ data, idToken }) => {

        const url = `${process.env.REACT_APP_API_BASE_URL}/subscription`

        const config = {
            "headers": {
                "Authorization": idToken
            }
        }

        const res = await axios.patch(url, data, config)
        return res.data;
    }
);

export const sendInvitation = createAsyncThunk(
    "invitation/post",
    async ({ data, idToken }) => {

        const url = `${process.env.REACT_APP_API_BASE_URL}/invitations`
        const config = {
            "headers": {
                "Authorization": idToken
            }
        }

        const res = await axios.post(url, data, config)
        return res.data;
    }
);

export const getAllSubs = createAsyncThunk(
    "getallsubs/get",
    async ({ email, idToken }) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/stripegetallsubs?email=${email}`
        const config = {
            "headers": {
                "Authorization": idToken
            }
        }

        const res = await axios.get(url, config)
        return res.data.subscriptions;
    }
);


export const contracts = createSlice({
    name: 'contracts',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(getContracts.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getContracts.fulfilled, (state, action) => {
            state.loading = false;
            state.contracts = action.payload;
        })
        builder.addCase(getContracts.rejected, (state, action) => {
            state.loading = false;
            state.apiError = true;
            state.apiErrorMessage = action.payload;
            state.contracts = [];
        })
        builder.addCase(getAgentInfo.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getAgentInfo.fulfilled, (state, action) => {
            state.loading = false;
            state.agentInfo = action.payload;
        })
        builder.addCase(getAgentInfo.rejected, (state, action) => {
            state.loading = false;
            state.apiError = true;
            state.apiErrorMessage = action.payload;
            state.agentInfo = {};
        })
        builder.addCase(getSubscriptions.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getSubscriptions.fulfilled, (state, action) => {
            state.loading = false;
            state.subscriptions = action.payload;
        })
        builder.addCase(getSubscriptions.rejected, (state, action) => {
            state.loading = false;
            state.apiError = true;
            state.apiErrorMessage = action.payload;
            state.subscriptions = [];
        })
        builder.addCase(getRealtorsForTC.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getRealtorsForTC.fulfilled, (state, action) => {
            state.loading = false;
            state.realtorsForTC = action.payload;
        })
        builder.addCase(getRealtorsForTC.rejected, (state, action) => {
            state.loading = false;
            state.apiError = true;
            state.apiErrorMessage = action.payload;
            state.realtorsForTC = [];
        })
        builder.addCase(createContract.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(createContract.fulfilled, (state, action) => {
            state.loading = false;
            state.newContractId = action.payload;
        })
        builder.addCase(createContract.rejected, (state, action) => {
            state.loading = false;
            state.apiError = true;
            state.apiErrorMessage = action.payload;
        })
        builder.addCase(getContract.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getContract.fulfilled, (state, action) => {
            state.loading = false;
            state.contract = action.payload;
        })
        builder.addCase(getContract.rejected, (state, action) => {
            state.loading = false;
            state.apiError = true;
            state.apiErrorMessage = action.payload;
            state.contract = {}
        })
        builder.addCase(updateContract.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(updateContract.fulfilled, (state, action) => {
            state.loading = false;
            state.updateContractId = action.payload;
        })
        builder.addCase(updateContract.rejected, (state, action) => {
            state.loading = false;
            state.apiError = true;
            state.apiErrorMessage = action.payload;
        })
        builder.addCase(createSubscription.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(createSubscription.fulfilled, (state, action) => {
            state.loading = false;
            state.newSubscriptionId = action.payload;
        })
        builder.addCase(createSubscription.rejected, (state, action) => {
            state.loading = false;
            state.apiError = true;
            state.apiErrorMessage = action.payload;
        })
        builder.addCase(updateSubscription.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(updateSubscription.fulfilled, (state, action) => {
            state.loading = false;
            state.newSubscriptionId = action.payload;
        })
        builder.addCase(updateSubscription.rejected, (state, action) => {
            state.loading = false;
            state.apiError = true;
            state.apiErrorMessage = action.payload;
        })
        builder.addCase(sendInvitation.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(sendInvitation.fulfilled, (state, action) => {
            state.loading = false;
        })
        builder.addCase(sendInvitation.rejected, (state, action) => {
            state.loading = false;
            state.apiError = true;
            state.apiErrorMessage = action.payload;
        })
        builder.addCase(getAllSubs.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getAllSubs.fulfilled, (state, action) => {
            state.loading = false;
            state.stripeSubs = action.payload;
        })
        builder.addCase(getAllSubs.rejected, (state, action) => {
            state.loading = false;
            state.apiError = true;
            state.apiErrorMessage = action.payload;
            state.stripeSubs = []
        })
    }
})

// export const { saveNewSubscriptionId } = contracts.actions;
export default contracts.reducer;