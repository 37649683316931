import { useLocation, Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';

const RequireAgent = ({ children }) => {

    const location = useLocation();
    let pathname = location.pathname

    const { role, agentEmail } = useSelector((state) => state.auth);

    if (role === "Transaction Coordinator" && !agentEmail) {
        return <Navigate to="/select-agent" state={{ from: location }} replace />;
    }

    return children
}

export default RequireAgent