import { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { getContract, archiveContract } from "../../features/contractSlice"

import { formatAddress, determineAuthorizationV2, determineAccess } from "../../utils/Helper"
import { canAddContractRoles } from "../../utils/Constants"
import { format, parseISO } from 'date-fns';

import NotAuthorized from '../Others/NotAuthorized'

const ArchiveContract = () => {
    let access = false;

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams()

    const { email, role, agentEmail, idToken } = useSelector((state) => state.auth);
    const { contract, stripeSubs, agentInfo, loading, apiError, apiErrorMessage } = useSelector((state) => state.contracts)

    const [data, setData] = useState(contract)
    const [notes, setNotes] = useState("")

    useEffect(() => {
        const contract_id = id
        dispatch(getContract({ contract_id, idToken }))
    }, [])

    let { msg, freeTrial, active } = determineAuthorizationV2(role, stripeSubs, agentInfo)

    const submitFormData = (e) => {
        e.preventDefault()

        const archiveData = {
            contract_id: data.contract_id,
            updatedBy: email,
            contract_archive_notes: notes
        }

        dispatch(archiveContract({ archiveData, idToken }))
            .unwrap()
            .then(() => {
                navigate(`/`);
            })
            .catch((error) => {
                console.error('Error archiving contract:', error);
            });
    }

    access = determineAccess(role, email, agentEmail, contract)

    if (!access) return <NotAuthorized />

    return (
        <div className='min-h-screen'>
            <div className="container-xl lg:container m-auto px-4 py-6">
                {(active || freeTrial) ? (
                    <div>
                        <div className='text-2xl text-center font-semibold mb-6 uppercase text-red-600'>Delete Contract</div>
                        <button className='w-[150px] h-[36px] bg-gray-500 hover:bg-gray-600 text-white rounded-lg text-center text-sm'>
                            <Link to={"/"}>Dashboard</Link>
                        </button>
                        <div>
                            <div className='font-bold text-xl mt-5'>
                                Address - {formatAddress(data.address, data.city, data.state, data.zipcode)}
                            </div>
                            <div>
                                Contract Acceptance Date:  {format(parseISO(data.contract_acceptance_date), 'MM/dd/yyyy')}
                            </div>
                            <div className='mb-2'>
                                Contract Status: <span className='font-bold'>{data.contract_status}</span>
                            </div>
                        </div>

                        {canAddContractRoles.includes(role) ?
                            <div>
                                <form className="mt-5" onSubmit={submitFormData}>
                                    <div className='mb-2'>
                                        <textarea
                                            className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                            name="seller_agent_email"
                                            value={notes}
                                            placeholder="Archive notes..."
                                            type="email"
                                            onChange={(e) => setNotes(e.target.value)}
                                        />
                                    </div>

                                    <button
                                        className='uppercase h-[36px] bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg text-center text-sm'
                                        type="submit">
                                        Delete contract
                                    </button>
                                </form>
                                {apiError && <div>{apiErrorMessage}</div>}
                            </div>
                            : <div>You are not authorized to delete a Contract</div>}
                    </div>
                ) : (
                    <div>
                        You have not subscribed to Contract To Keys.
                        <button
                            className='w-[150px] h-[36px] bg-gray-500 hover:bg-gray-600 text-white rounded-lg text-center text-sm'>
                            <Link to={"/sub/"}>Subscribe</Link>
                        </button>
                    </div>
                )}

            </div>
        </div>
    )
}

export default ArchiveContract