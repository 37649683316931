import Home1 from "../../assets/Home1.png"
import Home2 from "../../assets/Home2.png"
import Home3 from "../../assets/Home3.png"
import HomeChrome1 from "../../assets/Home1Chrome.png"
import HomeChrome2 from "../../assets/Home2Chrome.png"

const About = () => {
    return (
        <div className='min-h-screen'>
            <div className="container-xl lg:container m-auto px-4 py-6">
                <div className='text-2xl text-center font-semibold mb-6 uppercase'>Frequently Asked Questions (FAQ)</div>

                <div className="mt-4">
                    <div className="font-semibold italic mb-2">
                        What are the problems Realtors face between a home contract acceptance to home closing?
                    </div>
                    <div>
                        Realtors face numerous challenges in guiding a real estate transaction from contract acceptance to a successful closing. Financing glitches, low appraisals, inspection problems, title issues, changes in buyers' finances, sellers' remorse, and missed contractual deadlines can all threaten to delay or derail a deal. Realtors must proactively manage expectations, maintain constant communication between all parties, resolve disputes, find solutions to repair and price concerns, secure alternative financing if necessary, and meet all timelines. With so many potential obstacles arising between contract and closing, realtors must stay vigilant to ensure transactions progress smoothly through the closing process and avoid costly delays or cancellations.

                    </div>
                </div>
                <div className="mt-4">
                    <div className="font-semibold italic mb-2">
                        How does the Contract To Keys application help Realtors?
                    </div>
                    <div>
                        Contract To Keys streamlines the real estate transaction process by providing Realtors with an intuitive web application to manage all tasks from contract to closing. Our mission is to simplify contract-to-close coordination for Realtors through user-friendly task tracking and automated reminders accessible across all devices (desktops, tablets and mobile). By keeping Realtors organized and informed, Contract To Keys aims to reduce mistakes and delays while creating seamless real estate experience
                    </div>
                </div>

                <div className="mt-4">
                    <div className="font-semibold italic mb-2">
                        What are the different roles/personas that are available?
                    </div>
                    <ul>
                        <li>Buyer - The person(s) buying a house</li>
                        <li>Seller - The person(s) selling a house</li>
                        <li>Buyer Agent - Represents Buyer</li>
                        <li>Seller Agent - Represents Seller</li>
                        <li>Transaction Coordinator-Buyer - Works with a Buyer Agent</li>
                        <li>Transaction Coordinator-Seller - Works with a Seller Agent</li>
                    </ul>
                </div>

                <div className="mt-4">
                    <div className="font-semibold italic mb-2">
                        Who can create or edit a Contract in the app?
                    </div>
                    <ul>
                        <li>Buyer Agent</li>
                        <li>Seller Agent</li>
                        <li>Transaction Coordinator-Buyer</li>
                        <li>Transaction Coordinator-Seller</li>
                    </ul>
                </div>

                <div className="mt-4">
                    <div className="font-semibold italic mb-2">
                        Who can be the owners on a contract?
                    </div>
                    <ul>
                        <li>Who ever creates a contract, will be the owner</li>
                        <li>Once a contract is created, Agents (both Buyer/Seller) can add Transaction coordinators to the contract. Transaction coordinator on the contract can view and also edit the contract</li>
                        <li>When Transaction coordinator creates a contract, they will have to choose Agent
                            (A Buyer Transaction coordinator can only add Buyer Agent as owner and similarly a Seller Transaction coordinator can add Seller Agent as owner)
                        </li>
                    </ul>
                </div>

                <div className="mt-4">
                    <div className="font-semibold italic mb-2">
                        Who can view a contract?
                    </div>
                    <div>Anyone who is listed on the contract can view it. The role and email of the logged in user will be matched with the role and email on the contract. If the role and email matches, then the contract is visible to the user. Only 6 roles are currently allowed to access the application
                    </div>
                </div>

                <div className="mt-4">
                    <div className="font-semibold italic mb-2">
                        When is a contract marked as Completed?
                    </div>
                    <div>When all the steps on the workflow have a status of “Not required” or “Completed”, the contract will be marked completed by the application. A user cannot manually mark a contract as completed, user can only update the status on workflow steps
                    </div>
                </div>

                <div className="mt-4">
                    <div className="font-semibold italic mb-2">
                        Can a Contract be deleted?
                    </div>
                    <div>Yes, only contract owners can delete a contract
                    </div>
                </div>

                <div className="mt-4">
                    <div className="font-semibold italic mb-2">
                        How will the user get notified of past due or upcoming tasks?
                    </div>
                    <div>A daily email with all pending tasks that are past due date or upcoming in next 48 hours will be sent to all the emails listed on the Contract
                    </div>
                </div>

                <div className="mt-4">
                    <div className="font-semibold italic mb-2">
                        How do I add Contract To Keys web app to my Home screen on iPhone?
                    </div>
                    <div>
                        <div className="mb-2">For Safari browser, please see below</div>
                        <div className="grid grod-cols-1 md:grid-cols-3 gap-4">
                            <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                <img className="rounded-t-lg" src={Home1} alt="" />
                            </div>
                            <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                <img className="rounded-t-lg" src={Home2} alt="" />
                            </div>
                            <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                <img className="rounded-t-lg" src={Home3} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="mt-10">
                        <div className="mb-2">For Chrome browser, please see below</div>
                        <div className="grid grod-cols-1 md:grid-cols-3 gap-4">
                            <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                <img className="rounded-t-lg" src={HomeChrome1} alt="" />
                            </div>
                            <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                <img className="rounded-t-lg" src={HomeChrome2} alt="" />
                            </div>
                            <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                <img className="rounded-t-lg" src={Home3} alt="" />
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default About