import { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { PatternFormat } from 'react-number-format';

import Spinner from '../Layout/Spinner'
import NotAuthorized from '../Others/NotAuthorized';

import { getContract, updateContract } from "../../features/contractSlice"
import { formatAddress, determineAccess } from '../../utils/Helper';

const EditContractInfo = () => {
    let access = false;

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams()

    const { role, email, idToken, agentEmail } = useSelector((state) => state.auth);
    const { contract, subscriptions, agentInfo, loading, apiError, apiErrorMessage } = useSelector((state) => state.contracts)

    const [formData, setFormData] = useState({})

    useEffect(() => {
        const contract_id = id
        dispatch(getContract({ contract_id, idToken }))
        setFormData(contract)
    }, [])

    const handleFormData = input => e => {
        const { value } = e.target

        setFormData(prevState => ({
            ...prevState,
            [input]: value
        }))
    }

    const submitFormData = (e) => {
        e.preventDefault()

        const data = { ...formData }
        dispatch(updateContract({ data, idToken }))
            .unwrap()
            .then(() => {
                navigate(`/contract-details/${data.contract_id}`);
            })
            .catch((error) => {
                console.error('Error updating contract:', error);
            });
    }

    access = determineAccess(role, email, agentEmail, contract)

    if (!access) return <NotAuthorized />

    if (loading) return <Spinner />

    return (
        <div className='min-h-screen'>
            <div className="container-xl lg:container m-auto px-4 py-6">
                <div className='text-2xl text-center font-semibold mb-6 uppercase'>Edit Contract</div>
                <div className='font-bold text-xl mb-5'>Address - {formatAddress(formData.address, formData.city, formData.state, formData.zipcode)}</div>

                <form onSubmit={submitFormData}>
                    <div>
                        {(role === "Agent" || role === "Transaction Coordinator") && (
                            <div>
                                <div>
                                    <div className='font-semibold text-xl mt-5'>Buyer</div>
                                    <div>
                                        <div className='mb-2'>
                                            <input
                                                className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                                name="buyer_name"
                                                defaultValue={formData.buyer_name}
                                                placeholder="Name"
                                                type="text"
                                                onChange={handleFormData("buyer_name")}
                                            />
                                        </div>

                                        <div className='mb-2'>
                                            <input
                                                className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                                name="buyer_email"
                                                defaultValue={formData.buyer_email}
                                                placeholder="Email"
                                                type="email"
                                                onChange={handleFormData("buyer_email")}
                                            />
                                        </div>
                                        <div className='mb-2'>
                                            <PatternFormat
                                                className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                                format="(###) ### ####"
                                                mask="_"
                                                name="buyer_phone"
                                                value={formData.buyer_phone}
                                                placeholder="Mobile Phone"
                                                type="text"
                                                onChange={handleFormData("buyer_phone")}
                                            >
                                            </PatternFormat>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className='font-semibold text-xl mt-5'>Co-Buyer</div>
                                    <div>
                                        <div className='mb-2'>
                                            <input
                                                className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                                name="co_buyer_name"
                                                defaultValue={formData.co_buyer_name}
                                                placeholder="Name"
                                                type="text"
                                                onChange={handleFormData("co_buyer_name")}
                                            />
                                        </div>
                                        <div className='mb-2'>
                                            <input
                                                className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                                name="co_buyer_email"
                                                defaultValue={formData.co_buyer_email}
                                                placeholder="Email"
                                                type="email"
                                                onChange={handleFormData("co_buyer_email")}
                                            />
                                        </div>

                                        <div className='mb-2'>
                                            <PatternFormat
                                                className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                                format="(###) ### ####"
                                                mask="_"
                                                name="co_buyer_phone"
                                                value={formData.co_buyer_phone}
                                                placeholder="Mobile Phone"
                                                type="text"
                                                onChange={handleFormData("co_buyer_phone")}
                                            >
                                            </PatternFormat>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className='font-semibold text-xl mt-5'>Seller</div>
                                    <div>
                                        <div className='mb-2'>
                                            <input
                                                className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                                name="seller_name"
                                                defaultValue={formData.seller_name}
                                                placeholder="Name"
                                                type="text"
                                                onChange={handleFormData("seller_name")}
                                            />
                                        </div>

                                        <div className='mb-2'>
                                            <input
                                                className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                                name="seller_email"
                                                defaultValue={formData.seller_email}
                                                placeholder="Email"
                                                type="email"
                                                onChange={handleFormData("seller_email")}
                                            />
                                        </div>
                                        <div className='mb-2'>
                                            <PatternFormat
                                                className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                                format="(###) ### ####"
                                                mask="_"
                                                name="seller_phone"
                                                value={formData.seller_phone}
                                                placeholder="Mobile Phone"
                                                type="text"
                                                onChange={handleFormData("seller_phone")}
                                            >
                                            </PatternFormat>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className='font-semibold text-xl mt-5'>Co-Seller</div>
                                    <div>
                                        <div className='mb-2'>
                                            <input
                                                className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                                name="co_seller_name"
                                                defaultValue={formData.co_seller_name}
                                                placeholder="Name"
                                                type="text"
                                                onChange={handleFormData("co_seller_name")}
                                            />
                                        </div>
                                        <div className='mb-2'>
                                            <input
                                                className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                                name="co_seller_email"
                                                defaultValue={formData.co_seller_email}
                                                placeholder="Email"
                                                type="email"
                                                onChange={handleFormData("co_seller_email")}
                                            />
                                        </div>

                                        <div className='mb-2'>
                                            <PatternFormat
                                                className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                                format="(###) ### ####"
                                                mask="_"
                                                name="co_seller_phone"
                                                value={formData.co_seller_phone}
                                                placeholder="Mobile Phone"
                                                type="text"
                                                onChange={handleFormData("co_seller_phone")}
                                            >
                                            </PatternFormat>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div>
                            <div className='font-semibold text-xl mt-5'>Loan Officer</div>
                            <div>
                                <div className='mb-2'>
                                    <input
                                        className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                        name="loan_officer"
                                        defaultValue={formData.loan_officer}
                                        placeholder="Name"
                                        type="text"
                                        onChange={handleFormData("loan_officer")}
                                    />
                                </div>

                                <div className='mb-2'>
                                    <input
                                        className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                        name="loan_officer_email"
                                        defaultValue={formData.loan_officer_email}
                                        placeholder="Email"
                                        type="email"
                                        onChange={handleFormData("loan_officer_email")}
                                    />
                                </div>
                                <div className='mb-2'>
                                    <PatternFormat
                                        className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                        format="(###) ### ####"
                                        mask="_"
                                        name="loan_officer_phone"
                                        // defaultValue={loan_officer_phone}
                                        value={formData.loan_officer_phone}
                                        placeholder="Mobile Phone"
                                        type="text"
                                        onChange={handleFormData("loan_officer_phone")}
                                    >
                                    </PatternFormat>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='font-semibold text-xl mt-5'>Title Company</div>
                            <div>
                                <div className='mb-2'>
                                    <input
                                        className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                        name="title_company"
                                        defaultValue={formData.title_company}
                                        placeholder="Name"
                                        type="text"
                                        onChange={handleFormData("title_company")}
                                    />
                                </div>

                                <div className='mb-2'>
                                    <input
                                        className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                        name="title_company_email"
                                        defaultValue={formData.title_company_email}
                                        placeholder="Email"
                                        type="email"
                                        onChange={handleFormData("title_company_email")}
                                    />
                                </div>
                                <div className='mb-2'>
                                    <PatternFormat
                                        className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                        format="(###) ### ####"
                                        mask="_"
                                        name="title_company_phone"
                                        value={formData.title_company_phone}
                                        placeholder="Mobile Phone"
                                        type="text"
                                        onChange={handleFormData("title_company_phone")}
                                    >
                                    </PatternFormat>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='flex justify-between mt-5'>
                        <button
                            className='uppercase h-[36px] bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg text-center text-sm'>
                            <Link to="/">Dashboard</Link>

                        </button>
                        <button
                            className='uppercase h-[36px] bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg text-center text-sm'
                            type="submit">
                            save contract
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default EditContractInfo