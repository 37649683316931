import { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { PatternFormat } from 'react-number-format';
import validator from 'validator';

import { invalidPhone, invalidPassword } from "../../utils/Validations"
import { signUp } from '../../utils/CognitoAuth';

const Signup = () => {
    const navigate = useNavigate()

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const handleSubmit = async (e) => {

        e.preventDefault()
        if (
            validator.isEmpty(name) ||
            validator.isEmpty(email) ||
            validator.isEmpty(phone) ||
            validator.isEmpty(password) ||
            validator.isEmpty(confirmPassword)
        ) {
            setError(true)
            setErrorMessage("All fields are required")
        } else if (!validator.isEmail(email)) {
            setError(true)
            setErrorMessage("Please enter valid email")
        } else if (invalidPhone(phone)) {
            setError(true)
            setErrorMessage("Please enter valid phone")
        } else if (invalidPassword(password)) {
            setError(true)
            setErrorMessage("Invalid password, please see password instructions above")
        } else if (password !== confirmPassword) {
            setError(true)
            setErrorMessage("Both passwords dont match")
        } else {
            setError(false)
            let lowercaseEmail = email.toLowerCase()

            try {
                await signUp(name, lowercaseEmail, phone, password)
                navigate(`/confirm-signup/${lowercaseEmail}`)
            } catch (e) {
                setError(true)
                console.log("Error signing up:", e.message)
                setErrorMessage(e.message)
            }
        }
    }

    return (
        <div className='relative flex flex-col min-h-screen overflow-hidden'>
            <div className="w-full p-6 mx-auto bg-white rounded-md shadow-md lg:max-w-xl">
                <div className='font-bold uppercase text-2xl'>Register</div>
                <form onSubmit={handleSubmit}>
                    <div className='mb-2'>
                        <input
                            className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            name="name"
                            placeholder="Name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className='mb-2'>
                        <input
                            className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            name="email"
                            placeholder="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className='mb-2'>
                        <PatternFormat
                            className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            format="(###) ### ####"
                            mask="_"
                            name="buyer_agent_phone"
                            value={phone}
                            placeholder="Mobile Phone"
                            type="text"
                            onChange={(e) => setPhone(e.target.value)}
                        >
                        </PatternFormat>
                    </div>
                    <div className='mb-2'>
                        <input
                            className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            name="password"
                            placeholder="Password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className='mb-2'>
                        <input
                            className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <span className="text-xs italic text-indigo-600">Minimum 8 characters, 1 number, 1 special character, 1 uppercase letter and 1 lowercase letter</span>
                    </div>

                    {error && (
                        <div className='italic text-sm text-red-600'>{errorMessage}</div>
                    )}
                    <div className="mt-6">
                        <button type="submit" className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-indigo-700 rounded-md hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600">
                            Register
                        </button>
                    </div>

                </form>
            </div>
        </div>
    )
}

export default Signup