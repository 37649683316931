import { useState } from 'react'
import validator from 'validator'
import { useNavigate } from "react-router-dom";

import { invalidPassword } from "../../utils/Validations"
import { resetPassword } from '../../utils/CognitoAuth';

const ResetPassword = () => {
    const navigate = useNavigate()

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [confirmationCode, setConfirmationCode] = useState("")
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (
            validator.isEmpty(email) ||
            validator.isEmpty(confirmationCode) ||
            validator.isEmpty(password) ||
            validator.isEmpty(confirmPassword)
        ) {
            setError(true)
            setErrorMessage("All fields are required")
        } else if (!validator.isEmail(email)) {
            setError(true)
            setErrorMessage("Please enter valid email")
        } else if (invalidPassword(password)) {
            setError(true)
            setErrorMessage("Invalid password, please see password instructions above")
        } else if (password !== confirmPassword) {
            setError(true)
            setErrorMessage("Both passwords dont match")
        } else {
            setError(false)
            try {
                const result = await resetPassword(email, confirmationCode, password)
                navigate("/login")
            } catch (e) {
                setError(true)
                console.log("Error confirming user:", e)
                setErrorMessage(e.message)
            }
        }
    }

    return (
        <div className='relative flex flex-col min-h-screen overflow-hidden'>
            <div className="w-full p-6 mx-auto bg-white rounded-md shadow-md lg:max-w-xl">
                <div className='font-bold uppercase text-2xl'>Reset Password</div>

                <form onSubmit={handleSubmit}>
                    <div className='mb-2'>
                        <input
                            className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            name="email"
                            placeholder="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className='mb-2'>
                        <input
                            className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            name="confirmationCode"
                            placeholder="Confirmation Code"
                            type="text"
                            value={confirmationCode}
                            onChange={(e) => setConfirmationCode(e.target.value)}
                        />
                    </div>
                    <div className='mb-2'>
                        <input
                            className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            name="password"
                            placeholder="Password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className='mb-2'>
                        <input
                            className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <span className="text-xs italic text-indigo-600">Minimum 8 characters, 1 number, 1 special character, 1 uppercase letter and 1 lowercase letter</span>
                    </div>

                    {error && (
                        <div className='italic text-sm text-red-600'>{errorMessage}</div>
                    )}
                    <div className="mt-6">
                        <button type="submit" className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-indigo-700 rounded-md hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ResetPassword