import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid';

import { loadStripe } from "@stripe/stripe-js";
import {
    Elements,
    CardElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import axios from "axios";

import { createSubscription } from "../../features/contractSlice"

const stripePromise = loadStripe(
    process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY
);

const CheckoutForm = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams()

    const { email, idToken } = useSelector((state) => state.auth);

    const stripe = useStripe();
    const elements = useElements();

    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [succeeded, setSucceeded] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault()
        setProcessing(true);

        if (!stripe || !elements) {
            return;
        }

        let priceId, amount, subType

        switch (id) {
            case "annual":
                priceId = process.env.REACT_APP_STRIPE_RECURRING_ANNUAL_PRICE_ID
                amount = 99.99
                subType = "annual"
                break;
            case "monthly":
                priceId = process.env.REACT_APP_STRIPE_RECURRING_MONTHLY_PRICE_ID
                amount = 9.99
                subType = "monthly"
                break;
            default:
                break
        }

        try {
            // Create the subscription on the server
            const config = {
                "headers": {
                    "Authorization": idToken
                }
            }

            const url = `${process.env.REACT_APP_API_BASE_URL}/stripepaymentintent`
            const data = {
                email: email,
                priceId: priceId
            }

            const response = await axios.post(url, data, config);

            const { subscriptionId, clientSecret, currentPeriodEnd } = response.data;

            // Confirm the subscription with the card details
            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        email: email,
                    },
                },
            });

            if (result.error) {
                setError(`Payment failed: ${result.error.message}`);
                setProcessing(false);
            } else {
                setError(null);
                setSucceeded(true);
                setProcessing(false);

                const data = {
                    subscription_id: uuidv4(),
                    realtor_email: email,
                    stripe_subscription_id: subscriptionId,
                    sub_type: subType,
                    sub_amount: amount,
                    sub_payment_status: "paid",
                    sub_expiry_date: currentPeriodEnd
                }

                dispatch(createSubscription({ data, idToken }))
                navigate("/sub")
            }
        } catch (err) {
            setError("Failed to create subscription: " + err.message);
            setProcessing(false);
        }
    }

    return (
        <div className='min-h-64 px-4 py-6'>
            <div>
                <button className='w-[150px] h-[36px] bg-gray-500 hover:bg-gray-600 text-white rounded-lg text-center text-sm'>
                    <Link to={"/sub/"}>Go Back</Link>
                </button>

                <div className='text-center text-xl mb-5'>
                    You are signing up for recurring <span className="font-bold uppercase">{id}</span> subscription
                </div>
                <div className="">
                    <p>Once you subscribe, you can cancel anytime before your current subscription ends</p>
                    <p>If you forget to cancel, payment will be deducted for the next cycle (<span className="font-bold uppercase">{id}</span>) and there will be <span className="font-bold uppercase text-red-900 text-xl">no</span>  refund</p> </div>
            </div>
            <div className="mt-5 border rounded p-5">
                <div>Subscriber Email: <span className="font-bold">{email}</span></div>
                <form className="mt-2" onSubmit={handleSubmit}>

                    <CardElement />
                    {error && <div>{error}</div>}
                    {succeeded && <div className="text-green-600 italic">Subscription succeeded!</div>}
                    <button
                        disabled={processing || !stripe}
                        className="mt-4 align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg bg-gray-900 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                    >
                        {processing ? "Processing..." : "Subscribe"}
                    </button>
                </form>
                <div className="mt-2 text-sm italic font-semibold">Contract To Keys does NOT store Credit Card information. Payment is processed by Stripe</div>
            </div>
        </div>
    )
}

const PaymentCheckout = () => {
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm />
        </Elements>
    );
}

export default PaymentCheckout