import { useLocation, Navigate } from "react-router-dom";
import { parseIdToken } from "../../utils/Helper"
import { getNewIdToken } from "../../utils/CognitoAuth"
import { setNewTokens, logout } from "../../features/authSlice"
import { useSelector, useDispatch } from 'react-redux';

const RequireAuth = ({ children }) => {
    const dispatch = useDispatch()
    const { isAuthenticated, idToken, refreshToken, refreshTokenExp, agentEmail, role } = useSelector(state => state.auth);
    const location = useLocation();
    let pathname = location.pathname

    let idTokenExpired = checkIdTokenExpiration(idToken)
    const refreshTokenExpired = checkRefreshTokenExpiration(refreshTokenExp)

    if (refreshTokenExpired) {
        dispatch(logout())
        return <Navigate to="/login" />;
    }

    if (idTokenExpired) {
        const { IdToken, AccessToken } = getNewIdToken(refreshToken)
        dispatch(setNewTokens({ IdToken, AccessToken }))
        return <Navigate to={pathname} />;
    }

    return children
}

export default RequireAuth

const checkIdTokenExpiration = (idToken) => {
    if (!idToken) {
        return true
    }

    const idTokenParsed = parseIdToken(idToken)
    let exp = idTokenParsed.exp

    let expired = new Date(exp * 1000) - Date.now()

    if (expired < 0) {
        return true
    }

    return false
}

const checkRefreshTokenExpiration = (refreshTokenExp) => {
    if (!refreshTokenExp) {
        return true
    }

    let expired = refreshTokenExp - Date.now()

    if (expired < 0) {
        return true
    }

    return false

}