import {
    CognitoIdentityProviderClient,
    InitiateAuthCommand,
    SignUpCommand,
    ConfirmSignUpCommand,
    ForgotPasswordCommand,
    ConfirmForgotPasswordCommand,
    GlobalSignOutCommand,
} from "@aws-sdk/client-cognito-identity-provider";

export const cognitoClient = new CognitoIdentityProviderClient({
    region: process.env.REACT_APP_REGION,
});

export const signUp = async (name, email, phone_number, password) => {
    let phone_US_Code = "+1" + phone_number.replace("(", "").replace(")", "").replaceAll(" ", "")

    const params = {
        ClientId: process.env.REACT_APP_CLIENTID,
        Username: email,
        Password: password,
        UserAttributes: [
            { Name: "email", Value: email },
            { Name: "name", Value: name },
            { Name: "phone_number", Value: phone_US_Code },
        ],
    };

    try {
        const command = new SignUpCommand(params);
        const response = await cognitoClient.send(command);
        return response;
    } catch (error) {
        console.error("Error signing up: ", error);
        throw error;
    }
};

export const confirmSignUp = async (email, code) => {

    const params = {
        ClientId: process.env.REACT_APP_CLIENTID,
        Username: email,
        ConfirmationCode: code,
    };

    try {
        const command = new ConfirmSignUpCommand(params);
        const response = await cognitoClient.send(command);
        return true;
    } catch (error) {
        console.error("Error confirming sign up: ", error);
        throw error;
    }

};


export const signIn = async (email, password) => {
    const params = {
        AuthFlow: "USER_PASSWORD_AUTH",
        ClientId: process.env.REACT_APP_CLIENTID,
        AuthParameters: {
            USERNAME: email,
            PASSWORD: password,
        },
    };

    try {
        const command = new InitiateAuthCommand(params);
        const { AuthenticationResult } = await cognitoClient.send(command);

        if (AuthenticationResult) {
            return AuthenticationResult;
        }
    } catch (error) {
        console.error("Error signing in: ", error);
        throw error;
    }

};

export const forgotPassword = async (email) => {

    const params = {
        ClientId: process.env.REACT_APP_CLIENTID,
        Username: email,
    };

    try {
        const command = new ForgotPasswordCommand(params);
        const response = await cognitoClient.send(command);
        return response;
    } catch (error) {
        console.error("Error sending Forgot Password: ", error);
        throw error;
    }
};


export const getNewIdToken = async () => {
    const refreshToken = localStorage.getItem("refreshToken")
    const email = localStorage.getItem("email")

    const params = {
        AuthFlow: "REFRESH_TOKEN_AUTH",
        ClientId: process.env.REACT_APP_CLIENTID,
        AuthParameters: {
            USERNAME: email,
            REFRESH_TOKEN: refreshToken,
        },
    };

    try {
        const command = new InitiateAuthCommand(params);
        const { AuthenticationResult } = await cognitoClient.send(command);
        if (AuthenticationResult) {
            return AuthenticationResult;
        }
    } catch (error) {
        console.error("Error refreshing tokens: ", error);
        throw error;
    }
};

export const resetPassword = async (email, confirmationCode, newPassword) => {
    const params = {
        ClientId: process.env.REACT_APP_CLIENTID,
        Username: email,
        ConfirmationCode: confirmationCode,
        Password: newPassword
    };

    try {
        const command = new ConfirmForgotPasswordCommand(params);
        const response = cognitoClient.send(command);
        return response;
    } catch (error) {
        console.error("Error sending Forgot Password: ", error);
        throw error;
    }
}

export const logoutUser = () => {
    const params = {
        ClientId: process.env.REACT_APP_CLIENTID,
        AccessToken: localStorage.getItem("accessToken")
    };

    try {
        const command = new GlobalSignOutCommand(params);
        const response = cognitoClient.send(command);
        return response;
    } catch (error) {
        console.error("Error logging out: ", error);
        throw error;
    }
}
