import { useState } from 'react'
import { useDispatch } from 'react-redux';
import validator from 'validator'
import { useNavigate, Link, useParams } from "react-router-dom";

import { signIn } from "../../utils/CognitoAuth"
import { login } from "../../features/authSlice"

import { roles } from "../../utils/Constants"

const Login = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [role, setRole] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!roles.includes(role)) {
            setError(true)
            setErrorMessage("Please select valid role")
        } else if (validator.isEmpty(email) || validator.isEmpty(password)) {
            setError(true)
            setErrorMessage("Email and password are required")
        } else if (!validator.isEmail(email)) {
            setError(true)
            setErrorMessage("Please enter valid email")
        } else {
            try {
                const AuthenticationResult = await signIn(email, password)
                const { AccessToken, IdToken, RefreshToken } = AuthenticationResult
                dispatch(login({ email, role, IdToken, AccessToken, RefreshToken }))
                setError(false)
                navigate("/")
            } catch (e) {
                setError(true)
                setErrorMessage(e.message)
            }
        }
    }

    return (
        <div className='relative flex flex-col min-h-screen overflow-hidden'>
            <div className="w-full p-6 mx-auto bg-white rounded-md shadow-md lg:max-w-xl">
                <div className='font-bold uppercase text-2xl'>Login</div>
                <form onSubmit={handleSubmit} className='mt-5'>
                    <div className='mb-2 flex justify-between'>
                        <div className='font-bold italic text-xl'>{role}
                        </div>
                    </div>

                    <div className='mb-2'>
                        <select className='col-span-1 block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opaci ty-40'
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                        >
                            <option>Select Role</option>
                            {roles.map((role, i) => (
                                <option key={i}>{role}</option>
                            ))}
                        </select>
                    </div>

                    <div className='mb-2'>
                        <input
                            className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            name="email"
                            placeholder="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className='mb-2'>
                        <input
                            className='block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            name="password"
                            placeholder="Password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className='flex justify-between'>
                        <Link
                            to="/forgot-password"
                            className="text-sm italic text-indigo-500"
                        >
                            Forgot Password
                        </Link>
                        <Link
                            to="/signup"
                            className="italic font-bold"
                        >
                            Register a new user?
                        </Link>
                    </div>
                    {error && (
                        <div className='italic text-sm text-red-600'>{errorMessage}</div>
                    )}
                    <div className="mt-6">
                        <button type="submit" className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-indigo-700 rounded-md hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600">
                            Login
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login