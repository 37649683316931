import React, { useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { format, parseISO } from 'date-fns';

import { getContract } from "../../features/contractSlice"
import { canAddContractRoles } from '../../utils/Constants';
import { formatAddress, determineAccess } from '../../utils/Helper';

import Spinner from "../Layout/Spinner"
import NotAuthorized from '../Others/NotAuthorized';

const ContractDetails = () => {
    let access = false;

    const { id } = useParams()
    const { role, email, idToken, agentEmail } = useSelector((state) => state.auth);
    const { contract, loading, apiError, apiErrorMessage } = useSelector((state) => state.contracts)

    const dispatch = useDispatch()

    useEffect(() => {
        const contract_id = id
        dispatch(getContract({ contract_id, idToken }))
    }, [dispatch, id, idToken])

    if (loading) return <Spinner />

    access = determineAccess(role, email, agentEmail, contract)

    if (!access) return <NotAuthorized />

    return (
        <div>
            {Object.keys(contract).length > 0 && (
                <div className='min-h-screen px-4 py-6'>
                    <div className='container-xl lg:container m-auto'>

                        {canAddContractRoles.includes(role) && (
                            <div className='grid grid-cols-2 md:grid-cols-4 gap-2'>
                                <button className='w-[150px] h-[36px] bg-gray-500 hover:bg-gray-600 text-white rounded-lg text-center text-sm'>
                                    <Link to={"/"}>Dashboard</Link>
                                </button>
                                <button className='w-[150px] h-[36px] bg-blue-500 hover:bg-blue-600 text-white rounded-lg text-center text-sm mb-2' >
                                    <Link to={`/edit-contract-steps/${contract.contract_id}`}>Edit Workflow</Link>
                                </button>
                                <button className='w-[150px] h-[36px] bg-cyan-500 hover:bg-cyan-600 text-white rounded-lg text-center text-sm mb-2' >
                                    <Link to={`/edit-contract-info/${contract.contract_id}`}>Edit Information</Link>
                                </button>
                                <button className='w-[150px] h-[36px] bg-red-500 hover:bg-red-600 text-white rounded-lg text-center text-sm mb-2' >
                                    <Link to={`/archive-contract/${contract.contract_id}`}>Delete Contract</Link>
                                </button>
                            </div>
                        )}

                        <div className="bg-white px-6 py-8">
                            <div className='text-xl text-center font-semibold uppercase'>Contract Details</div>
                            <div className='text-center font-bold text-xl mb-5'>
                                {formatAddress(contract.address, contract.city, contract.state, contract.zipcode)}
                            </div>
                            <div className='text-center'>
                                Contract Acceptance Date: {format(parseISO(contract.contract_acceptance_date), 'MM/dd/yyyy')}
                            </div>
                            <div className='mb-2 text-center'>
                                Contract Status: <span className='font-bold'>{contract.contract_status}</span>
                            </div>

                            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 '>
                                {contract.agent_name && (
                                    <div>
                                        <div className='text-lg text-center font-semibold mb-2'>Agent</div>
                                        <div className='text-center'>
                                            <div>{contract.agent_name}</div>
                                            <div>{contract.agent_email}</div>
                                            <div>{contract.agent_phone}</div>
                                        </div>
                                    </div>
                                )}

                                {contract.buyer_name && (
                                    <div>
                                        <div className='text-lg text-center font-semibold mb-2'>Buyer</div>
                                        <div className='text-center'>
                                            <div>{contract.buyer_name}</div>
                                            <div>{contract.buyer_email}</div>
                                            <div>{contract.buyer_phone}</div>
                                        </div>
                                    </div>
                                )}
                                {contract.co_buyer_name && (
                                    <div>
                                        <div className='text-lg text-center font-semibold mb-2'>Co-Buyer</div>
                                        <div className='text-center'>
                                            <div>{contract.co_buyer_name}</div>
                                            <div>{contract.co_buyer_email}</div>
                                            <div>{contract.co_buyer_phone}</div>
                                        </div>
                                    </div>
                                )}
                                {contract.txn_coordinator_name && (
                                    <div>
                                        <div className='text-lg text-center font-semibold mb-2'>Transaction Coordinator-Buyer </div>
                                        <div className='text-center'>
                                            <div>{contract.txn_coordinator_name}</div>
                                            <div>{contract.txn_coordinator_email}</div>
                                            <div>{contract.txn_coordinator_phone}</div>
                                        </div>
                                    </div>
                                )}
                                {contract.seller_name && (
                                    <div>
                                        <div className='text-lg text-center font-semibold mb-2'>Seller</div>
                                        <div className='text-center'>
                                            <div>{contract.seller_name}</div>
                                            <div>{contract.seller_email}</div>
                                            <div>{contract.seller_phone}</div>
                                        </div>
                                    </div>
                                )}
                                {contract.co_seller_name && (
                                    <div>
                                        <div className='text-lg text-center font-semibold mb-2'>Co-Seller</div>
                                        <div className='text-center'>
                                            <div>{contract.co_seller_name}</div>
                                            <div>{contract.co_seller_email}</div>
                                            <div>{contract.co_seller_phone}</div>
                                        </div>
                                    </div>
                                )}

                                {contract.loan_officer && (
                                    <div>
                                        <div className='text-lg text-center font-semibold mb-2'>Loan Officer</div>
                                        <div className='text-center'>
                                            <div >{contract.loan_officer}</div>
                                            <div >{contract.loan_officer_phone}</div>
                                            <div >{contract.loan_officer_email}</div>
                                        </div>
                                    </div>
                                )}
                                {contract.title_company && (
                                    <div>
                                        <div className='text-lg text-center font-semibold mb-2'>Title Company</div>
                                        <div className='text-center'>
                                            <div >{contract.title_company}</div>
                                            <div >{contract.title_company_phone}</div>
                                            <div >{contract.title_company_email}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="mx-auto bg-white px-6 py-8">
                            <div className='text-xl text-center font-semibold mb-6'>Workflow</div>
                            <div>
                                {contract.steps.map((step, index) => {
                                    return (
                                        <div key={index} className='text-center flex flex-col md:grid md:gap-2 md:grid-cols-7 border-b md:text-left'>
                                            <div className='col-span-1 m-2 font-bold'>Step {index + 1}</div>
                                            <div className='col-span-2 m-2'>{step.title}</div>
                                            <div className='col-span-1 m-2'>{format(parseISO(step.due_date), 'MM/dd/yyyy')}</div>
                                            <div className='col-span-1 m-2'>{step.status}</div>
                                            <div className='col-span-2 m-2'>{step.comments}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <button className='w-[150px] h-[36px] bg-gray-500 hover:bg-gray-600 text-white rounded-lg text-center text-sm'>
                            <Link to={"/"}>Dashboard</Link>
                        </button>

                    </div>
                </div>
            )}
        </div>
    )

}

export default ContractDetails