import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { format, parseISO } from 'date-fns';

import Spinner from '../Layout/Spinner';

import { determineAuthorizationV2, toProperCase } from "../../utils/Helper"
import { getAllSubs } from '../../features/contractSlice'

const ManageSubscriptions = () => {
    const dispatch = useDispatch()

    const { role, email, idToken } = useSelector((state) => state.auth);
    const { stripeSubs, agentInfo, loading } = useSelector((state) => state.contracts)

    useEffect(() => {
        dispatch(getAllSubs({ email, idToken }))
    }, [])

    let { active } = determineAuthorizationV2(role, stripeSubs, agentInfo)

    if (loading) {
        return <Spinner />
    }

    return (
        <div className='min-h-64 px-4 py-6'>
            {stripeSubs.length > 0 && active ? (
                <div>
                    <table className="w-full table-auto">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Subscription Duration
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Amount
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {stripeSubs.map((sub, index) => {
                                return <tr key={index} className={`${sub.status === "active" ? "bg-green-200" : " "} hover:bg-gray-100`}>
                                    <td className="px-6 py-4 text-sm font-medium text-gray-900 text-blue-500 font-bold">
                                        {format(parseISO(new Date(sub.current_period_start * 1000).toISOString().split("T")[0]), "MM/dd/yyyy")}- {format(parseISO(new Date(sub.current_period_end * 1000).toISOString().split("T")[0]), "MM/dd/yyyy")}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                        $ {sub.items.data[0].price.unit_amount / 100}
                                    </td>
                                    <td className="px-6 py-4 text-sm font-medium text-gray-900 text-blue-500 font-bold">
                                        {toProperCase(sub.status)}
                                        <p>
                                            {sub.status === "active" && (
                                                <Link className="text-sm text-red-600" to={`/sub/cancel/${sub.id}`}>Cancel</Link>
                                            )}
                                        </p>

                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div>
                    <div className='mt-5 text-xl font-bold text-center'>Subscription Options</div>
                    <div className='text-center grid grid-cols-1 gap-4 md:grid-cols-2'>
                        <div className="relative flex flex-col mt-6 text-gray-700 bg-gray-100 shadow-md bg-clip-border rounded-xl w-100">
                            <div className="p-6">
                                <h5 className="block mb-2 font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                                    Yearly Subscription
                                </h5>
                                <div className='italic font-semibold'>Best value for money</div>
                                <div className="block font-sans text-base antialiased font-light leading-relaxed text-inherit">
                                    <p>
                                        <span className="font-bold">$99.99/year</span>
                                    </p>
                                </div>
                            </div>
                            <div className="p-6 pt-0">
                                <button
                                    className="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg bg-gray-900 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                                    type="submit">
                                    <Link to={`/sub/payment/annual`}>Subscribe</Link>

                                </button>

                            </div>
                        </div>
                        <div className="relative flex flex-col mt-6  bg-gray-500 text-white shadow-md bg-clip-border rounded-xl w-100">
                            <div className="p-6">
                                <h5 className="block mb-2 font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                                    Monthly Subscription
                                </h5>
                                <div className="block font-sans text-base antialiased font-light leading-relaxed text-inherit">
                                    <p>
                                        <span className="font-bold">$9.99/month</span>
                                    </p>
                                </div>
                            </div>
                            <div className="p-6 pt-0">
                                <button
                                    className="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg bg-gray-900 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                                    type="submit">
                                    <Link to={`/sub/payment/monthly`}>Subscribe</Link>

                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            )}


        </div>
    )
}

export default ManageSubscriptions