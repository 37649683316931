import validator from "validator"

export const inValidDate = (date) => {
    const newDate = new Date(date)

    if (!validator.isDate(newDate)) {
        return true
    }

    return false

}

export const duration = (contract_acceptance_date, closing_date) => {
    const agreement = new Date(contract_acceptance_date)
    const closing = new Date(closing_date)

    const difference = (closing.getTime() - agreement.getTime()) / (1000 * 3600 * 24)

    return difference

}

export const invalidPassword = (password) => {
    if (validator.isStrongPassword(password,
        {
            minLength: 8,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1
        })) {
        return false
    }

    return true
}

export const invalidPhone = (phone) => {
    const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (regex.test(phone)) {
        return false
    }

    return true
}

