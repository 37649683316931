import { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Spinner from '../Layout/Spinner'
import NotAuthorized from '../Others/NotAuthorized'

import { getContract, updateContract } from "../../features/contractSlice"
import { formatAddress, determineAuthorization, determineContract_Status, determineAccess } from '../../utils/Helper';
import { status } from "../../utils/Constants"

const EditContractSteps = () => {
    let access = false;

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams()

    const [list, setList] = useState([])
    const [editContract, setEditContract] = useState([])

    const [step6Error, setStep6Error] = useState(false)
    const [step7Error, setStep7Error] = useState(false)

    const { role, email, idToken, agentEmail } = useSelector((state) => state.auth);
    const { contract, subscriptions, agentInfo, loading, apiError, apiErrorMessage } = useSelector((state) => state.contracts)

    useEffect(() => {
        const contract_id = id
        dispatch(getContract({ contract_id, idToken }))
        setEditContract(contract)
        setList(contract.steps)
    }, [])

    let { msg, active } = determineAuthorization(role, subscriptions, agentInfo)

    const handleChangeDueDate = (index, field, value) => {

        const newList = list.map((item, i) => {
            if (i === index) {
                const newObj = { ...item, "due_date": value }
                return newObj
            } else {
                return item
            }
        })
        setList(newList)

        if (index === 4) {
            setStep6Error(true)
        } else {
            setStep6Error(false)
        }

        if (index === 5) {
            setStep7Error(true)
        } else {
            setStep7Error(false)
        }
    }

    const handleChangeStatus = (index, field, value) => {

        const newList = list.map((item, i) => {
            if (i === index) {
                const newObj = { ...item, "status": value }
                return newObj
            } else {
                return item
            }
        })
        setList(newList)
    }

    const handleChangeComments = (index, field, value) => {

        const newList = list.map((item, i) => {
            if (i === index) {
                const newObj = { ...item, "comments": value }
                return newObj
            } else {
                return item
            }
        })
        setList(newList)
    }

    const submitFormData = (e) => {
        e.preventDefault()

        const step_12_date = list[11].due_date

        const newList = list.map((item, i) => {
            if (i === 12) {
                const newObj = { ...item, "due_date": step_12_date }
                return newObj
            } else {
                return item
            }
        })

        const status = determineContract_Status(newList)
        const data = { ...editContract, contract_status: status, closing_date: step_12_date, steps: newList }

        dispatch(updateContract({ data, idToken }))
            .unwrap()
            .then(() => {
                navigate(`/contract-details/${data.contract_id}`);
            })
            .catch((error) => {
                console.error('Error creating contract:', error);
            });

    }

    access = determineAccess(role, email, agentEmail, contract)

    if (!access) return <NotAuthorized />

    if (loading) return <Spinner />

    return (
        <div className='min-h-screen'>
            <div className='container-xl lg:container m-auto px-4 py-6'>
                <div className='text-2xl text-center font-semibold mb-6 uppercase'>Workflow</div>
                <div className='font-bold text-xl m-5'>Address - {formatAddress(contract.address, contract.city, contract.state, contract.zipcode)}</div>
                <form onSubmit={submitFormData}>
                    {list.map((step, index) => {
                        return <div key={index} >
                            <div className='text-center flex flex-col md:grid md:gap-2 md:grid-cols-7 mb-4' >
                                <div className='col-span-1 m-2 text-center font-bold'>Step {step.step}</div >
                                <div className='col-span-2 m-2 text-left'>{step.title}</div>

                                <input
                                    className={`col-span-1 block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40 ${((step6Error && (index === 5)) || (step7Error && (index === 6))) ? " text-red-500" : ""}`}
                                    type="date"
                                    value={step.due_date}
                                    pattern="\d{4}-\d{2}-\d{2}"
                                    onChange={(e) => handleChangeDueDate(index, "due_date", e.target.value)}
                                // onChange={e => {
                                //     list[index].due_date = e.target.value
                                //     index === 4 ? setStep6Error(true) : setStep6Error(false)
                                //     index === 5 ? setStep7Error(true) : setStep7Error(false)
                                //     setList([...list])
                                // }}
                                />

                                <select
                                    className='col-span-1 block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opaci ty-40'
                                    value={step.status}
                                    onChange={(e) => handleChangeStatus(index, "status", e.target.value)}
                                // onChange={e => {
                                //     list[index].status = e.target.value
                                //     setList([...list])
                                // }}
                                >
                                    <option>Select Status</option>
                                    {status.map((s, i) => (
                                        <option key={i}>{s}</option>
                                    ))}
                                </select>

                                <input
                                    className='col-span-2 block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                    value={step.comments}
                                    placeholder="Notes"
                                    onChange={(e) => handleChangeComments(index, "comments", e.target.value)}
                                // onChange={e => {
                                //     list[index].comments = e.target.value
                                //     setList([...list])
                                // }}
                                />

                            </div >
                            {step6Error && (index === 4) ?
                                <div className='text-center text-red-500 text-sm italic'>Change Date on Step 6 accordingly</div>
                                : null}
                            {step7Error && (index === 5) ?
                                <div className='text-center text-red-500 text-sm italic'>Change Date on Step 7 accordingly</div>
                                : null}
                        </div>
                    })}

                    <div className='flex justify-between mt-5'>
                        <button
                            className='uppercase h-[36px] bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg text-center text-sm'>
                            <Link to="/">Dashboard</Link>

                        </button>
                        <button
                            className='uppercase h-[36px] bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg text-center text-sm'
                            type="submit">
                            Save Contract
                        </button>
                    </div>
                </form >
            </div>

        </div >
    )
}

export default EditContractSteps